import React from 'react'

function BusinessAssociateThankYou() {
  return (
    <React.Fragment>
        <div className="container">
            <div className="content">
                <div className="wrapper-1">
                    <div className="wrapper-2">
                        <h1 className="text-exp-green">Thank You!</h1>
                        <p>Thank you for partnering with us!</p>
                        <p>We look forward to a successful and rewarding collaboration ahead.</p>
                         
                        <div className="text-center my-5">
                            <img src={process.env.PUBLIC_URL + 'assets/images/thankyou-tick.png'} alt="tick" className="img-fluid"/>
                        </div>
                        
                        <a className="go-home" href="/">
                            go home
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
  )
}

export default BusinessAssociateThankYou